.bg-mskcc-header {
    background-color: #1c75cd;
    color: #ffffff;
}

.bg-mskcc-subheader {
    background-color: #064785;
    color: #ffffff;
}

.brand-title-link,
.brand-title-link:hover,
.brand-title-link:focus,
.brand-title-link:active,
.brand-title-link:visited {
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.5em;
}