.gene-page {
    max-width: 85%;
    display: inline-block;
}

.header {
  background-color: rgb(241, 241, 241);
}

.title {
  text-align: left;
  font-size: 2em;
}
.title-container {
    margin-top: 25px;
    overflow-x: auto;
}

.subtitle {
  text-align: left;
  font-size: 1.1em;
}

table {
  text-align: left;
  margin-top: 20px;
  border: 1px solid #dee2e6;
}

table td, table th {
  padding: 5px;
}

.tooltip > .tooltip-inner {
  background-color: white; 
  color: black; 
  border-color: black;
  border: 1px solid black;
  min-width: max-content;
}

.footer {
  margin-top: 20px;
  text-align: left;
}
